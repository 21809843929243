<footer>
  <div class="grid">
    <div class="grid__inner">

      <div
        class="grid__cell grid__cell--span-2-desktop grid__cell--span-3-tablet grid__cell--span-4-phone flex flex--column">
        <p class="heading">Credit Union</p>

        <div class="flex flex--column height--full justify--between">
          <ul>
            <!-- <li>
          <a href="" class="colorize">Open an account</a>
        </li>
        <li>
          <a href="" class="colorize">Apply for a loan</a>
        </li>
        <li>
          <a href="" class="colorize">Apply for a credit card</a>
        </li> -->
            <li>
              <a routerLink="/disclosures" class="colorize">Disclosures</a>
            </li>
            <li>
              <a routerLink="/privacy" class="colorize">Privacy</a>
            </li>
            <li>
              <a href="https://co-opcreditunions.org/locator/?ref=multiplicu.com&sc=1" rel="noopener" target="_blank"
                class="colorize">Shared branches
                & ATMs</a>
            </li>
            <li *ngIf="scholarshipLink$ | async as scholarshipLink">
              <a href="{{ scholarshipLink }}" rel="noopener" target="_blank" class="colorize">Franklin O
                Walstrand Scholarship</a>
            </li>
          </ul>


          <div class="flex flex--column contact mt--2">
            <p class="flex">
              <svg width="20" height="20" viewBox="0 0 24 24" fill="currentColor" role="img"
                aria-labelledby="phone-description">
                <desc id="phone-description">Icon of a phone</desc>
                <path
                  d="M6.54 5c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79h1.51m9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75l1.2-1.19M7.5 3H4c-.55 0-1 .45-1 1c0 9.39 7.61 17 17 17c.55 0 1-.45 1-1v-3.49c0-.55-.45-1-1-1c-1.24 0-2.45-.2-3.57-.57a.84.84 0 0 0-.31-.05c-.26 0-.51.1-.71.29l-2.2 2.2a15.149 15.149 0 0 1-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02A11.36 11.36 0 0 1 8.5 4c0-.55-.45-1-1-1z" />
              </svg>

              <a href="tel:+14178653912" alt="Call us at 417-865-3912">417.865.3912</a>
            </p>
            <p class="flex">
              <svg fill="currentColor" role="img" width="20" height="20" viewBox="0 0 24 24"
                aria-labelledby="email-description">
                <desc id="email-description">Icon of a piece of mail</desc>
                <path
                  d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5l8-5v10zm-8-7L4 6h16l-8 5z" />
              </svg>

              <a href="mailto:info@multiplicu.com" alt="Email us at info@multiplicu.com">info@multiplicu.com</a>
            </p>
            <p class="flex">
              <svg fill="currentColor" role="img" width="20" height="20" viewBox="0 0 24 24"
                aria-labelledby="route-description">
                <desc id="route-description">Icon of a route</desc>
                <path
                  d="M9.78 11.16l-1.42 1.42a7.282 7.282 0 0 1-1.79-2.94l1.94-.49c.32.89.77 1.5 1.27 2.01zM11 6L7 2L3 6h3.02c.02.81.08 1.54.19 2.17l1.94-.49C8.08 7.2 8.03 6.63 8.02 6H11zm10 0l-4-4l-4 4h2.99c-.1 3.68-1.28 4.75-2.54 5.88c-.5.44-1.01.92-1.45 1.55c-.34-.49-.73-.88-1.13-1.24L9.46 13.6c.93.85 1.54 1.54 1.54 3.4v5h2v-5c0-2.02.71-2.66 1.79-3.63c1.38-1.24 3.08-2.78 3.2-7.37H21z" />
              </svg>

              Routing number 286580917
            </p>

          </div>
        </div>
      </div>

      <div class="grid__cell grid__cell--span-2-desktop grid__cell--span-3-tablet grid__cell--span-4-phone">
        <p class="heading">Locations</p>
        <ul>
          <li>
            <address>
              <strong>East</strong><br>
              1850 S Blackman Rd<br>
              Suite 100<br>
              Springfield, MO 65809<br>
            </address>
          </li>
          <li>
            <address>
              <strong>Midtown</strong><br>
              818 N Benton Ave<br>
              Suite 100<br>
              Springfield, MO 65802<br>
            </address>
          </li>
          <li>
            <address>
              <strong>South</strong><br>
              1017 E Republic Rd<br>
              Springfield, MO 65807
            </address>
          </li>
          <!-- <li>
            <address>
              <strong>Cox Medical Center South</strong><br>
              1000 E Primrose St<br>
              Springfield, MO 65807
            </address>
          </li> -->
        </ul>
      </div>

      <div class="grid__cell grid__cell--span-2-desktop grid__cell--span-3-tablet grid__cell--span-4-phone">
        <p class="heading">Hours</p>
        <ul>
          <li>
            <p>
              <strong>Monday ⁠– Friday</strong><br>
              9:00 am ⁠– 5:00 pm <em>lobby</em><br>
              8:00 am ⁠– 5:30 pm <em>drive-thru</em>
            </p>
          </li>
          <li>
            <p>
              <strong>Saturday (South only)</strong><br>
              8:30 am ⁠– 12:30 pm <em>drive-thru</em>
            </p>
          </li>
          <li>
            <p>
              <strong>24/7 self-service</strong><br>
              <small>Available at all locations</small>
            </p>
          </li>
        </ul>
      </div>

      <div
        class="grid__cell grid__cell--span-2-desktop grid__cell--span-3-tablet grid__cell--span-4-phone flex flex--column">
        <p class="heading none block--tablet">&nbsp;</p>

        <div class="flex flex--column height--full justify--between">

          <div class="flex align--center">
            <svg width="80" height="68" viewBox="0 0 141 119"
              style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;" role="img"
              aria-labelledby="slogan-description">
              <desc id="slogan-description">Bank better without a bank</desc>
              <g></g>
              <g>
                <g transform="matrix(1.10838,0,0,1.10838,2.74815,-26.2861)">
                  <g transform="matrix(1.01192,0,0,1,-0.146982,0)"><text x="12.331px" y="48.771px"
                      style="font-family:'AvenirNext-DemiBold', 'Avenir Next', sans-serif;font-weight:600;font-size:28.871px;fill:#98ca40;">B
                      <tspan x="29.538px 44.638px 52.554px 60.898px 75.997px "
                        y="48.771px 48.771px 48.771px 48.771px 48.771px ">etter</tspan>
                    </text></g>
                  <g transform="matrix(1.01192,0,0,1,-0.146982,0)"><text x="12.331px" y="74.43px"
                      style="font-family:'AvenirNext-DemiBold', 'Avenir Next', sans-serif;font-weight:600;font-size:28.871px;fill:#158f45;">B
                      <tspan x="29.538px 43.945px 59.275px 73.48px 79.745px 95.076px "
                        y="74.43px 74.43px 74.43px 74.43px 74.43px 74.43px ">anking
                      </tspan>
                    </text></g>
                  <g transform="matrix(1.01885,0,-0.284565,1,28.8372,0)"><text x="8.348px" y="101.891px"
                      style="font-family:'AvenirNext-DemiBold', 'Avenir Next', sans-serif;font-weight:600;font-size:28.871px;fill:#158f45;">W</text>
                  </g>
                  <g transform="matrix(0.965915,0,0,1,1.26953,0)"><text x="37.246px" y="101.801px"
                      style="font-family:'AvenirNext-DemiBold', 'Avenir Next', sans-serif;font-weight:600;font-size:28.871px;fill:#158f45;">i
                      <tspan x="43.521px 51.875px 67.273px 83.421px 98.762px 107.115px 112.87px "
                        y="101.801px 101.801px 101.801px 101.801px 101.801px 101.801px 101.801px ">
                        thout a</tspan>
                    </text></g>
                  <g transform="matrix(1.01192,0,0,1,-0.149409,0)"><text x="12.534px" y="127.4px"
                      style="font-family:'AvenirNext-DemiBold', 'Avenir Next', sans-serif;font-weight:600;font-size:28.871px;fill:#0e506e;">B
                      <tspan x="29.742px 44.148px 59.479px 73.684px " y="127.4px 127.4px 127.4px 127.4px ">ank.
                      </tspan>
                    </text></g>
                </g>
                <rect x="0" y="-0.005" width="6.003" height="118.468" style="fill:#0e506e;" />
              </g>
            </svg>

          </div>

          <div class="text--small">
            <p>Federally insured by NCUA to at least $250,000.</p>
            <p>If you are using a screen reader and are having problems using this
              website, please call
              <a href="tel:+14178653912" class="underline" alt="Call us at 417-865-3912">417.865.3912</a> for
              assistance.
            </p>
          </div>

          <div class="text--small">
            <button xcu-theme-toggle></button>
          </div>
        </div>
      </div>

      <div class="grid__cell grid__cell--span-8 copyright">
        <xcu-stripe-separator></xcu-stripe-separator>

        <div class="copyright__content">
          <div class="flex align--center">
            <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2"
              viewBox="0 0 84 79" width="24" height="24" class="footer__mark" role="img"
              aria-labelledby="mark-description">
              <desc id="mark-description">{{ appName }} M mark</desc>
              <path d="m67.673.007 13.46 13.461-25.986 25.986-13.461-13.462 25.986-25.985z" fill="#43823d" />
              <path d="m15.701 78.899-13.461-13.46 25.986-25.986 13.461 13.46z" fill="#9aca3c" />
              <path d="m67.673 78.899-25.987-25.986 13.461-13.46 25.986 25.986z" fill="#9aca3c" />
              <path d="m2.24 13.467 13.461-13.46 25.986 25.986-13.461 13.46z" fill="#43823d" />
              <path d="m28.226 39.453 13.461-13.46 13.46 13.46-13.461 13.461-13.461-13.461z" fill="#014f6e" />
              <g fill="#64a944">
                <path d="m67.737 0h15.641v78.832h-15.641z" />
                <path d="m0 0h15.641v78.832h-15.641z" />
              </g>
            </svg>

            <div class="text--small">
              <p>&copy; {{ copyrightYear }} {{ appName }}. All rights reserved.</p>
            </div>
          </div>

          <div class="flex align--center social">
            <a href="https://www.facebook.com/multiplicu/" target="_blank" rel="noopener"
              alt="Multipli Credit Union on Facebook">
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" role="img"
                aria-labelledby="desc-fb">
                <desc id="desc-fb">Facebook logo</desc>
                <path
                  d="M23.998 12c0-6.628-5.372-12-11.999-12C5.372 0 0 5.372 0 12c0 5.988 4.388 10.952 10.124 11.852v-8.384H7.078v-3.469h3.046V9.356c0-3.008 1.792-4.669 4.532-4.669 1.313 0 2.686.234 2.686.234v2.953H15.83c-1.49 0-1.955.925-1.955 1.874V12h3.328l-.532 3.469h-2.796v8.384c5.736-.9 10.124-5.864 10.124-11.853z" />
              </svg>
            </a>

            <a href="https://www.instagram.com/multiplicu/" target="_blank" rel="noopener"
              alt="Multipli Credit Union on Instagram">
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" role="img"
                aria-labelledby="desc-insta">
                <desc id="desc-insta">Instagram logo</desc>
                <path
                  d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913a5.885 5.885 0 001.384 2.126A5.868 5.868 0 004.14 23.37c.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558a5.898 5.898 0 002.126-1.384 5.86 5.86 0 001.384-2.126c.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913a5.89 5.89 0 00-1.384-2.126A5.847 5.847 0 0019.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227a3.81 3.81 0 01-.899 1.382 3.744 3.744 0 01-1.38.896c-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421a3.716 3.716 0 01-1.379-.899 3.644 3.644 0 01-.9-1.38c-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678a6.162 6.162 0 100 12.324 6.162 6.162 0 100-12.324zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405a1.441 1.441 0 01-2.88 0 1.44 1.44 0 012.88 0z" />
              </svg>
            </a>

            <a href="https://x.com/multiplicu/" target="_blank" rel="noopener"
              alt="Multipli Credit Union on X">
              <svg width="1200" height="1227" viewBox="0 0 1200 1227" fill="currentColor" role="img"
                xmlns="http://www.w3.org/2000/svg" aria-labelledby="desc-x">
                <desc id="desc-x">X logo</desc>
                <path
                  d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                  fill="currentColor" />
              </svg>
            </a>

            <span class="brand">multiplicu</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
