export enum AccountNames {
	HolidayClub = 'Holiday Club',
	IRA = 'Individual Retirement Accounts',
	HSA = 'Health Savings Accounts',
}

export enum SavingsNames {
	SubSavings = 'Sub Savings',
	upTo99 = 'up to $99',
	between100and2000 = '$100 - $2,000',
	between2000and10000 = '$2,000.01 - $10,000',
	between10000and20000 = '$10,000.01 - $20,000',
	over20000 = '$20,000 +',
}

export enum CDNames {
	SixMonths = '6 month',
	TwelveMonths = '12 months',
	EighteenMonths = '18 months',
	TwentyFourMonths = '24 months',
}

export enum VehicleNames {
	Auto = 'New and Used Autos',
	Recreational = 'Recreational Vehicles',
}

export enum CreditCardNames {
	Classic = 'Classic',
	Platinum = 'Platinum',
	ShareSecured = 'Share Secured',
}

export enum RealEstateNames {
	HomeEquity = 'Home Equity Line of Credit',
	FixedRate = 'Fixed Rate Mortgages',
}

export enum HomeLoanNames {
	FifteenYearFixed = '15 Year Fixed',
	ThirtyYearFixed = '30 Year Fixed',
	ThirtyYearFixedFHA = '30 Year Fixed FHA',
	ThirtyYearFixedVA = '30 Year Fixed VA',
	ThirtyYearUSDA = '30 Year USDA',
}

export enum HELOCNames {
	TwentyYearLOC = '20 Year',
}

export enum PersonalNames {
	ShareSecured = 'Share Secured Loan',
	Unsecured = 'Unsecured Loan',
}

export enum CertificateNames {
	SixMonths = '6 month',
	TwelveMonths = '12 months',
	EighteenMonths = '18 months',
	TwentyMonths = '20 months**',
	TwentyFourMonths = '24 months',
}

export const RateNames = {
	AccountNames,
	SavingsNames,
	CDNames,
	VehicleNames,
	CreditCardNames,
	RealEstateNames,
	HomeLoanNames,
	HELOCNames,
	PersonalNames,
	CertificateNames,
};

export enum RateCategoryNames {
	Vehicle = 'Vehicle',
	RealEstate = 'Real Estate',
	FixedRate = 'Fixed Rate Mortgages',
	Personal = 'Personal',
	CreditCards = 'Credit Card',
	Certificates = 'Certificates',
}
