import { Component, OnInit } from '@angular/core';

import { BannerService } from './../../_services/banner/banner.service';
import { MetaPixelService } from './../../_services/meta-pixel/meta-pixel.service';

@Component({
	selector: 'xcu-page',
	templateUrl: './public.component.html',
	styleUrls: ['./public.component.scss'],
})
export class PublicComponent implements OnInit {
	public constructor(
		public bannerService: BannerService,
		private _metaPixelService: MetaPixelService
	) {}

	public ngOnInit(): void {
		this._metaPixelService.initialize('667419563857632');
	}
}
