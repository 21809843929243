import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
	providedIn: 'root',
})
export class BannerService {
	public banner: {
		isEnabled: boolean;
		title?: string;
		shortTitle?: string;
		icon?: string;
		isFitWidth?: boolean;
		isDismissible?: boolean;
		isSticky?: boolean;
		isFloating?: boolean;
		position?: string;
	} = {
		isEnabled: false,
		isFitWidth: false,
		isDismissible: true,
		isFloating: false,
		isSticky: false,
		position: 'end',
	};

	public isBannerVisible: boolean = true;

	public get icon(): any {
		return this?._sanitizer?.bypassSecurityTrustHtml(this.banner.icon as string);
	}

	constructor(
		@Inject(DOCUMENT) private _document: Document,
		private _sanitizer: DomSanitizer
	) {}

	public setCookie(name: string, value: string, days: number): void {
		const date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		this._document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
	}

	public getCookie(name: string): string | null {
		const nameEQ = `${name}=`;
		const ca = this._document.cookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) === ' ') c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
		}
		return null;
	}

	public deleteCookie(name: string): void {
		this.setCookie(name, '', -1);
	}

	public hashCookie(content: string): string {
		let hash = 0;
		for (let i = 0; i < content.length; i++) {
			const char = content.charCodeAt(i);
			hash = (hash << 5) - hash + char;
			hash |= 0; // Convert to 32bit integer
		}
		return hash.toString();
	}
}
