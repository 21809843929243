<router-outlet></router-outlet>

<!-- <div
	*ngIf="bannerService.isBannerVisible"
	class="pointer-events-none fixed inset-x-0 bottom-0 z-20 sm:px-6 sm:pb-5 lg:mx-auto lg:max-w-screen-2xl lg:px-8"
>
	<div
		class="pointer-events-auto flex items-center justify-between gap-x-6 bg-tertiary-container-light px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5 dark:bg-tertiary-container-dark"
	>
		<p class="m-0 max-w-full p-0 text-sm leading-6">
			<a
				routerLink="/together"
				class="p-0 text-on-tertiary-container-light dark:text-on-tertiary-container-dark"
			>
				{{ bannerMessage }}&nbsp;<span aria-hidden="true">&rarr;</span>
			</a>
		</p>
		<button
			(click)="closeBanner()"
			type="button"
			class="-m-3 flex-none p-3 focus-visible:outline-offset-[-4px]"
		>
			<span class="sr-only">Dismiss</span>
			<svg
				class="h-5 w-5 text-white"
				viewBox="0 0 20 20"
				fill="currentColor"
				aria-hidden="true"
			>
				<path
					d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
				/>
			</svg>
		</button>
	</div>
</div> -->

<xcu-banner
	*ngIf="(auth.user | async) && updates.isUpdateAvailable"
	sticky
	floating
	fit-width
	dismissible="false"
	title="An update of {{ appName }} is available."
	shortTitle="An update is available"
>
	<button (click)="reload()" xcu-button--secondary>Reload</button>
</xcu-banner>
