import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
	Inject,
	Injectable,
	Optional,
	PLATFORM_ID,
	Renderer2,
	RendererFactory2,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

declare const fbq: any;

@Injectable({
	providedIn: 'root',
})
export class MetaPixelService {
	private _doc: Document;
	private _renderer: Renderer2;

	constructor(
		@Inject(DOCUMENT) private _injectedDocument: any,
		@Inject(PLATFORM_ID) private _platformId: Object,
		private rendererFactory: RendererFactory2,
		@Optional() private router: Router
	) {
		this._doc = _injectedDocument as Document;
		this._renderer = rendererFactory.createRenderer(null, null);

		if (router) {
			router.events
				.pipe(filter((event) => event instanceof NavigationEnd))
				.subscribe(() => {
					if (this.isLoaded()) {
						this.track('PageView');
					}
				});
		}
	}

	/** Initialize the Meta Pixel script */
	initialize(pixelId: string): void {
		if (!isPlatformBrowser(this._platformId)) {
			return;
		}

		if (this.isLoaded()) {
			console.warn('Meta Pixel script is already initialized.');
			return;
		}

		this.addPixelScript(pixelId);
	}

	/** Remove the Meta Pixel script */
	remove(): void {
		this.removePixelScript();
	}

	/** Track a Meta Pixel event */
	track(eventName: string, properties?: object): void {
		if (!isPlatformBrowser(this._platformId)) {
			return;
		}

		if (!this.isLoaded()) {
			console.warn('Meta Pixel script is not initialized.');
			return;
		}

		if (properties) {
			fbq('track', eventName, properties);
		} else {
			fbq('track', eventName);
		}
	}

	/** Adds the Meta Pixel script to the document head */
	private addPixelScript(pixelId: string): void {
		if (!isPlatformBrowser(this._platformId)) {
			return;
		}

		const pixelCode = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${pixelId}');
      fbq('track', 'PageView');`;

		const scriptElement = this._renderer.createElement('script');
		this._renderer.setAttribute(scriptElement, 'id', 'meta-pixel-script');
		this._renderer.setAttribute(scriptElement, 'type', 'text/javascript');
		this._renderer.setProperty(scriptElement, 'innerHTML', pixelCode);
		this._renderer.appendChild(this._doc.head, scriptElement);
	}

	/** Removes the Meta Pixel script from the document head */
	private removePixelScript(): void {
		if (!isPlatformBrowser(this._platformId)) {
			return;
		}

		const pixelElement = this._doc.getElementById('meta-pixel-script');
		if (pixelElement) {
			pixelElement.remove();
		}
	}

	/** Checks if the Meta Pixel script is already added */
	private isLoaded(): boolean {
		if (isPlatformBrowser(this._platformId)) {
			const pixelElement = this._doc.getElementById('meta-pixel-script');
			return !!pixelElement;
		}
		return false;
	}
}
