import { AfterViewInit, Component, QueryList, ViewChildren } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { NavLink } from '@multiplicu/ui/core';
import { XcuMegaMenuComponent } from '@multiplicu/ui/navigation/mega-menu';
import { EMPTY, Observable, of } from 'rxjs';
import { filter, pluck, switchMap } from 'rxjs/operators';

import { environment } from './../../../../environments/environment';
import { BoardMember } from './../../../_objects/board-member';
import { Employee } from './../../../_objects/employee';
import { RateNames } from './../../../_objects/rate-names';
import { User } from './../../../_objects/user';
import { AppService } from './../../../_services/app/app.service';
import { AuthService } from './../../../_services/auth/auth.service';
import { RateService } from './../../../_services/rate/rate.service';

@Component({
	selector: 'header[xcu-header]',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements AfterViewInit {
	public appName: string = environment.title;
	public isMobileNavOpen = false;

	public isMenuActive = false;

	public badges: {
		borrow: {
			auto: Observable<string>;
			realEstate: Observable<string>;
			personalLoan: Observable<string>;
			creditCards: Observable<string>;
		};
		invest: {
			certificates: Observable<string>;
		};
	} = {
		borrow: {
			auto: EMPTY,
			realEstate: EMPTY,
			personalLoan: EMPTY,
			creditCards: EMPTY,
		},
		invest: {
			certificates: EMPTY,
		},
	};

	public headerLinks: NavLink[] = [];
	public bankLink: NavLink;
	public borrowLink: NavLink;
	public investLink: NavLink;
	public wellnessLink: NavLink;
	public userNavLink: Observable<NavLink> = EMPTY;

	@ViewChildren(XcuMegaMenuComponent)
	public menus!: QueryList<XcuMegaMenuComponent>;

	public constructor(
		public auth: AuthService,
		public rateService: RateService,
		public appService: AppService,
		private _router: Router
	) {
		this.bankLink = {
			title: 'Bank',
			fragment: '/bank',
		};
		this.borrowLink = {
			title: 'Borrow',
			fragment: '/borrow',
		};
		this.investLink = {
			title: 'Invest',
			fragment: '/invest',
		};
		this.wellnessLink = {
			title: 'Financial Wellness',
			fragment: '/financial-wellness',
		};

		this.userNavLink = this.auth.user.pipe(
			switchMap((user: User | Employee | BoardMember) => {
				const links: NavLink[] = [];

				// Dashboard link
				if (
					(user as Employee).roles?.isEmployee ||
					(user as Employee).roles?.isRRCUEmployee
				) {
					links.push({ title: 'Dashboard', href: '/intranet/dashboard' });
				}

				// Management link
				if (
					[
						(user as Employee).roles?.isAdmin,
						(user as Employee).roles?.isSiteManager,
						(user as Employee).roles?.isRateManager,
						(user as Employee).roles?.isLobbySlideManager,
					].some((privilege) => privilege)
				) {
					links.push({ title: 'Management', href: '/manage' });
				}

				// Style Guide link
				if ((user as Employee).roles?.isDesigner) {
					links.push({ title: 'Style Guide', href: '/style-guide' });
				}

				// Board Reports link
				if ((user as Employee | BoardMember).roles?.isBoardMember) {
					links.push({ title: 'Board Reports', href: '/board' });
				}

				links.push(
					{
						title: 'Online Banking',
						href: this.appService.onlineBankingURL,
						external: true,
						dividerPosition: 'ABOVE',
					},
					{
						title: 'Sign out',
						onClick: () => this.auth.signOut(),
						dividerPosition: 'ABOVE',
					}
				);

				return of({
					title: user.displayName,
					links,
				} as NavLink);
			})
		);

		this.badges.borrow = {
			auto: this.rateService
				.getRateByName(RateNames.VehicleNames.Auto, this.rateService.auto)
				.pipe(pluck('APR')) as Observable<string>,
			realEstate: this.rateService
				.getRateByName(
					RateNames.HomeLoanNames.FifteenYearFixed,
					this.rateService.getRateByName(
						RateNames.RealEstateNames.FixedRate,
						this.rateService.realEstate
					)
				)
				.pipe(pluck('rate')) as Observable<string>,
			personalLoan: this.rateService
				.getRateByName(
					RateNames.PersonalNames.ShareSecured,
					this.rateService.personalLoan
				)
				.pipe(pluck('APR')) as Observable<string>,
			creditCards: this.rateService
				.getRateByName(RateNames.CreditCardNames.Platinum, this.rateService.creditCards)
				.pipe(pluck('APR')) as Observable<string>,
		};
	}

	public ngAfterViewInit(): void {
		// Listen for route end events and make sure to toggle off the menu.
		// This will prevent menus staying open when a link routes to a different
		// submodule route without refreshing the whole page.
		this._router.events
			.pipe(filter((event: Event) => event instanceof NavigationEnd))
			.subscribe(() =>
				this.menus.forEach((menu: XcuMegaMenuComponent) => {
					menu.close();

					// Blur all anchors
					menu.elementRef.nativeElement
						.querySelectorAll('a')
						.forEach((a: any) => a.blur());
				})
			);

		this.menus.forEach(
			(menu: XcuMegaMenuComponent, i: number, menus: XcuMegaMenuComponent[]) => {
				menu.toggled.subscribe((isActive: boolean) => {
					this.isMenuActive = isActive;

					if (!isActive) {
						return;
					}

					menus.forEach((otherMenu: XcuMegaMenuComponent, j: number) => {
						if (j === i) {
							return;
						}

						otherMenu.toggle(false, false);
					});
				});
			}
		);
	}
}
